/* Base */

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  user-select: none;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.slick-list {
  overflow: hidden;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
}

.slick-track::after,
.slick-track::before {
  content: '';
  display: table;
}

.slick-track::after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Theme */

.slick-next,
.slick-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 48px;
  height: 48px;
  transform: translateY(100%);
  color: #c8c7c5;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: #1d1d1b;
}

.slick-prev {
  right: 160px;
}

.slick-next {
  right: -15px;
}

.slick-dots {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 34px;
  transform: translateY(100%);
  list-style: none;
}

.slick-dots li {
  display: inline-block;
  vertical-align: top;
}

.slick-dots li:not(:last-child) {
  margin-right: 5px;
}

.slick-dots li button {
  display: block;
  position: relative;
  width: 38px;
  height: 48px;
}

.slick-dots li button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 38px;
  height: 3px;
  transform: translateY(-50%);
  background-color: #c8c7c5;
}

.slick-dots li button:focus::before,
.slick-dots li button:hover::before,
.slick-dots .slick-active button::before {
  background-color: #56aeb1;
}
