.image-gallery-icon {
  appearance: none;
  position: absolute;
  z-index: 4;
  border: 0;
  outline: none;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-out;
  filter: drop-shadow(0 2px 2px #1a1a1a);
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #56aeb1;
  }

  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}

.image-gallery-icon:focus {
  outline: 2px solid #56aeb1;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  width: 28px;
  height: 28px;
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 60px;
  height: 120px;
}

.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.image-gallery.fullscreen-modal {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-content {
  position: relative;
  top: 0;
  line-height: 0;
}

.image-gallery-content.fullscreen {
  background-color: #000;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

.image-gallery-slide-wrapper {
  position: relative;
  margin-bottom: 16px;
}

.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 110px);
}

.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}

.image-gallery-slides {
  overflow: hidden;
  position: relative;
  line-height: 0;
  text-align: center;
  white-space: nowrap;
}

.image-gallery-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.image-gallery-slide.center {
  position: relative;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}

.image-gallery-slide .image-gallery-description {
  position: absolute;
  bottom: 70px;
  left: 0;
  padding: 10px 20px;
  line-height: 1;
  white-space: normal;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}

.image-gallery-bullets {
  position: absolute;
  z-index: 4;
  right: 0;
  bottom: 20px;
  left: 0;
  width: 80%;
  margin: 0 auto;
}

.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}

.image-gallery-bullets .image-gallery-bullet {
  appearance: none;
  display: inline-block;
  outline: none;
  margin: 0 5px;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 2px 2px #1a1a1a;
  background-color: transparent;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.image-gallery-bullets .image-gallery-bullet:focus {
  border: 1px solid #56aeb1;
  transform: scale(1.2);
  background-color: #56aeb1;
}

.image-gallery-bullets .image-gallery-bullet.active {
  border: 1px solid #fff;
  transform: scale(1.2);
  background-color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    border: 1px solid #56aeb1;
    background-color: #56aeb1;
  }

  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #56aeb1;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}

.image-gallery-thumbnails-wrapper.left
  .image-gallery-thumbnails
  .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right
  .image-gallery-thumbnails
  .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}

.image-gallery-thumbnails-wrapper.left
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail {
  margin-top: 2px;
  margin-left: 0;
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}

.image-gallery-thumbnails-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
}

@media (min-width: 48em) {
  .image-gallery-thumbnails-container {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  }
}

@media (min-width: 80em) {
  .image-gallery-thumbnails-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

.image-gallery-thumbnail {
  outline: 0 solid #fff;
}

.image-gallery-thumbnail.active {
  outline-width: 6px;
  outline-offset: -6px;
}

.image-gallery-thumbnail:not(.active):focus,
.image-gallery-thumbnail:not(.active):hover {
  outline: 6px solid #d34e8e;
  outline-offset: -6px;
}

.image-gallery-thumbnail-image {
  transition: transform 0.3s ease-out;
}

.image-gallery-thumbnail-inner {
  display: block;
  overflow: hidden;
}

.image-gallery-thumbnail-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 5%;
  transform: translateY(-50%);
  text-shadow: 0 2px 2px #1a1a1a;
  white-space: normal;
  color: white;
}

.image-gallery-index {
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  padding: 10px 20px;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}
